<template>
  <div>
    <h1 style="padding-top: 15px">Unpacking Algorithmic Harms</h1>
    <b-tabs content-class="my-3">
      <Harms1></Harms1>
      <Harms2></Harms2>
      <Harms3></Harms3>
      <Harms4></Harms4>
    </b-tabs>
    <div
      style="
        display: flex;
        padding-top: 20px;
        padding-bottom: 10px;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="">
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
          ><img
            alt="Creative Commons License"
            style="border-width: 0"
            src="https://i.creativecommons.org/l/by/4.0/88x31.png"
        /></a>
      </div>

      <div class="">
        This work is licensed under a
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
          >Creative Commons Attribution 4.0 International License</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Harms1 from "@/components/Harms-Tab-1.vue";
import Harms2 from "@/components/Harms-Tab-2.vue";
import Harms3 from "@/components/Harms-Tab-3.vue";
import Harms4 from "@/components/Harms-Tab-4.vue";
export default {
  name: "Home",

  components: {
    Harms1: Harms1,
    Harms2: Harms2,
    Harms3: Harms3,
    Harms4: Harms4
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
