<template>
  <div class="harms">
    <Harms></Harms>
  </div>
</template>

<script>
import Harms from "@/components/Harms-Content.vue";
export default {
  name: "Home",

  components: {
    Harms: Harms
  },
  mounted() {},
  methods: {}
};
</script>
